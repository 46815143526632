<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M192.8,12.8H7.2c-3.5,0-6.4,2.9-6.4,6.4v11.5h198.5V19.2C199.3,15.7,196.4,12.8,192.8,12.8z"/>
      <path class="st0" d="M0.7,54.3c0,3.5,2.9,6.4,6.4,6.4h185.7c3.5,0,6.4-2.9,6.4-6.4V42.9H0.7V54.3z"/>
      <path class="st0" d="M24.1,111.4c0,41.9,34,75.9,75.9,75.9h0c41.9,0,75.9-34,75.9-75.9v-40H24.1V111.4z M100,82.7   c22.6,0,41,18.3,41,41c0,22.6-18.3,41-41,41c-22.6,0-41-18.3-41-41C59,101,77.4,82.7,100,82.7z"/>
      <path class="st0" d="M100,128.4c2.6,0,4.8-2.1,4.8-4.8c0-2.6-2.1-4.8-4.8-4.8c-2.6,0-4.8,2.1-4.8,4.8   C95.2,126.3,97.4,128.4,100,128.4z"/>
      <path class="st0" d="M100,152.7c16,0,29-13,29-29c0-16-13-29-29-29c-16,0-29,13-29,29C71,139.7,84,152.7,100,152.7z M100,107.9   c8.7,0,15.7,7,15.7,15.7s-7,15.7-15.7,15.7c-8.7,0-15.7-7-15.7-15.7S91.3,107.9,100,107.9z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconSecurityCamera',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

